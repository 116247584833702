import React from 'react';
import {
  Box,
  Button as CkButton,
  Flex,
  Image,
  Text,
  ChakraProvider,
} from '@chakra-ui/core';

import theme, { colors } from '@theme';
import { playLocation, appleLocation, cloudfrontBaseUrl } from '../lib/consts';

const H3 = ({ children, as, lineHeight, fontWeight, rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['md', 'lg']}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

const Button = ({ children, outlined, light, onClick }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick && onClick();
  };

  return (
    <CkButton
      w={68}
      borderColor={colors.primaryWTrans}
      color="primary"
      variant="outline"
      rounded="8px"
      py={6}
      onClick={handleClick}
    >
      {children}
    </CkButton>
  );
};

const Index = () => {
  const handleEmail = () => {
    window.location = 'mailto: vip@askzeta.com';
  };
  const onAppleClick = e => {
    e.preventDefault();
    window.location.href = appleLocation;
  };

  const onGoogleClick = e => {
    e.preventDefault();
    window.location.href = playLocation;
  };

  return (
    <ChakraProvider theme={theme}>
      <Flex
        direction="column"
        position="fixed"
        h="100%"
        w="100%"
        bg={colors.greenLight}
        justify="center"
        align="center"
      >
        <Text fontSize="8xl">
          <span role="img" aria-label="phone">
            📱
          </span>
        </Text>
        <Box h={10} />
        <Text textAlign={['center', 'auto']} fontSize={['4xl', '8xl']}>
          Find Zeta on your favorite app store.
        </Text>
        <Box h={15} />
        <H3 fontWeight="semibold" textAlign="center" maxW={70} px={5}>
          Click on your preferred store link:
        </H3>

        <Box h={20} />

        <Flex justify="center" wrap>
          <CkButton variant="unstyled" onClick={onGoogleClick} mr={8}>
            <Image
              src={`${cloudfrontBaseUrl}/app-store-icons/GooglePlayBadge.png`}
              w="130px"
              h="42px"
            />
          </CkButton>
          <CkButton variant="unstyled" onClick={onAppleClick}>
            <Image
              src={`${cloudfrontBaseUrl}/app-store-icons/AppleBadge.png`}
              w="130px"
              h="42px"
            />
          </CkButton>
        </Flex>

        <Box h={20} />
        <Button outlined onClick={handleEmail}>
          Need Help?
        </Button>
      </Flex>
    </ChakraProvider>
  );
};

export default Index;
